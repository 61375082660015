import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Cloud, Server, Network, Image, MessageSquare, Mic, FileText, Menu, X, ChevronDown } from 'lucide-react';

const App = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeExpertise, setActiveExpertise] = useState(null);

  console.log(scrollY);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const expertiseAreas = [
    { id: 1, name: 'Cloud Computing', description: 'Harness the power of the cloud with integritychain. Our scalable cloud solutions ensure your data is always accessible, secure, and ready to grow with your business, offering unmatched reliability in an interconnected world.', icon: <Cloud className="w-12 h-12 mb-4 text-blue-400" /> },
    { id: 2, name: 'Virtualization', description: 'Maximize your efficiency and reduce overheads with our cutting-edge virtualization services. We help you create seamless, high-performance virtual environments that are agile and cost-effective.', icon: <Server className="w-12 h-12 mb-4 text-green-400" /> },
    { id: 3, name: 'Computer Networks', description: 'From designing robust network architectures to maintaining secure, high-speed connectivity, our computer network solutions keep your business running smoothly, with the security and dependability you can trust.', icon: <Network className="w-12 h-12 mb-4 text-yellow-400" /> },
    { id: 4, name: 'Image Processing', description: "With integritychain's advanced image processing capabilities, we transform raw visual data into meaningful insights. Our solutions power applications in automation, machine learning, and intelligent systems, driving innovation in every pixel.", icon: <Image className="w-12 h-12 mb-4 text-purple-400" /> },
    { id: 5, name: 'Natural Language Processing', description: 'Elevate human-computer interaction with our NLP expertise. We develop intelligent systems that understand, interpret, and respond to natural language, bridging the gap between technology and communication.', icon: <MessageSquare className="w-12 h-12 mb-4 text-red-400" /> },
    { id: 6, name: 'Voice Engineering', description: 'Voice is the interface of the future. Our voice engineering solutions ensure seamless voice recognition and command interfaces, making technology more accessible and intuitive for users everywhere.', icon: <Mic className="w-12 h-12 mb-4 text-indigo-400" /> },
  ];

  const menuItems = ['About', 'Expertise', 'Why Us', 'Documentation', 'Contact'];

  return (
    <div className="bg-gray-900 text-white min-h-screen font-sans">
      {/* Futuristic Background */}
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-900 to-purple-900 opacity-50"></div>
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjMDAwMDAwMDAiPjwvcmVjdD4KPHBhdGggZD0iTTAgNUw1IDBaTTYgNEw0IDZaTS0xIDFMMSAtMVoiIHN0cm9rZT0iIzIwMjAzMCIgc3Ryb2tlLXdpZHRoPSIxIj48L3BhdGg+Cjwvc3ZnPg==')] opacity-20"></div>
      </div>

      {/* Header */}
      <header className="fixed w-full z-50 backdrop-blur-md bg-gray-900/30">
        <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <img src="logo.png" alt="integritychain Logo" className="h-8 w-12 mr-4" />
            <motion.h1 
              className="text-2xl font-bold"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              integritychain
            </motion.h1>
          </div>
          <div className="md:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
          <ul className="hidden md:flex space-x-6">
            {menuItems.map((item, index) => (
              <motion.li
                key={item}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <a href={`#${item.toLowerCase().replace(' ', '-')}`} className="hover:text-blue-400 transition border-b-2 border-transparent hover:border-blue-400 pb-1">{item}</a>
              </motion.li>
            ))}
          </ul>
        </nav>
      </header>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="fixed inset-0 bg-gray-900 z-40 md:hidden"
          >
            <div className="flex flex-col items-center justify-center h-full">
              {menuItems.map((item, index) => (
                <motion.a
                  key={item}
                  href={`#${item.toLowerCase().replace(' ', '-')}`}
                  className="text-2xl py-4 hover:text-blue-400 transition"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item}
                </motion.a>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Hero Section */}
      <section className="h-screen flex items-center justify-center relative overflow-hidden">
        <motion.div
          className="text-center z-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <img src="/logo.png" alt="integritychain Logo" className="h-32 w-48 mx-auto mb-8" />
          <h2 className="text-6xl font-bold mb-4">Building the Future with integritychain</h2>
          <p className="text-xl mb-8">Empowering businesses with innovative, reliable, and secure software solutions</p>
          <motion.a
            href="#expertise"
            className="bg-blue-500 text-white font-bold py-3 px-8 rounded-full inline-block relative overflow-hidden group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <span className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
            <span className="relative">Explore Our Expertise</span>
          </motion.a>
        </motion.div>
        <motion.div
          className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
          animate={{ y: [0, 10, 0] }}
          transition={{ repeat: Infinity, duration: 1.5 }}
        >
          <ChevronDown size={32} />
        </motion.div>
      </section>


      {/* About Section */}
      <section id="about" className="py-20 bg-gray-800 relative overflow-hidden">
        <div className="container mx-auto px-6 relative z-10">
          <motion.h2
            className="text-4xl font-bold mb-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            About integritychain
          </motion.h2>
          <motion.div
            className="text-xl leading-relaxed space-y-4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <p>
              At integritychain, we build the future of technology with trust at our core. Our mission is to empower businesses with innovative, reliable, and secure software solutions.
            </p>
            <p>
              With expertise in advanced technologies, we are committed to helping organizations navigate the evolving digital landscape, making sure they are always prepared for tomorrow's challenges.
            </p>
          </motion.div>
        </div>
        <div className="absolute inset-0 bg-blue-500 opacity-5 transform rotate-45 scale-150"></div>
      </section>

      {/* Expertise Section */}
      <section id="expertise" className="py-20 bg-gray-900 relative">
        <div className="container mx-auto px-6">
          <motion.h2
            className="text-4xl font-bold mb-12 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            Our Expertise
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {expertiseAreas.map((area, index) => (
              <motion.div
                key={area.id}
                className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-2xl transition cursor-pointer relative overflow-hidden group"
                onClick={() => setActiveExpertise(area)}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                whileHover={{ scale: 1.05 }}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-purple-500 opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
                <div className="relative z-10">
                  {area.icon}
                  <h3 className="text-2xl font-bold mb-4">{area.name}</h3>
                  <p className="text-sm">{area.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Why integritychain Section */}
      <section id="why-us" className="py-20 bg-gray-800 relative overflow-hidden">
        <div className="container mx-auto px-6 relative z-10">
          <motion.h2
            className="text-4xl font-bold mb-12 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            Why integritychain?
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { title: "Built on Trust", description: "Integrity is at the heart of everything we do. Our solutions are designed to be secure, reliable, and aligned with the needs of your business." },
              { title: "Future-Focused", description: "We stay ahead of technological trends to provide solutions that not only meet today's needs but anticipate the challenges of tomorrow." },
              { title: "Tailored Innovation", description: "We don't believe in one-size-fits-all. Every solution we deliver is customized to suit the unique needs of our clients, ensuring they always get the most out of their investment." },
              { title: "Proven Expertise", description: "Our team of seasoned engineers combines deep technical knowledge with a passion for innovation, delivering solutions that push the boundaries of what's possible." }
            ].map((item, index) => (
              <motion.div
                key={index}
                className="bg-gray-900 p-6 rounded-lg shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <h3 className="text-2xl font-bold mb-4">{item.title}</h3>
                <p>{item.description}</p>
              </motion.div>
            ))}
          </div>
          <motion.p
            className="text-center mt-12 text-xl"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            viewport={{ once: true }}
          >
            At integritychain, we're not just solving today's problems — we're building the future of technology, one solution at a time.
          </motion.p>
        </div>
        <div className="absolute inset-0 bg-blue-500 opacity-5 transform -rotate-45 scale-150"></div>
      </section>

      {/* Documentation Section */}
      <section id="documentation" className="py-20 bg-gray-900 relative overflow-hidden">
        <div className="container mx-auto px-6 relative z-10">
          <motion.h2
            className="text-4xl font-bold mb-12 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            Client Documentation
          </motion.h2>
          <motion.div
            className="max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-2xl overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="p-8">
              <h3 className="text-2xl font-bold mb-4">Access Your Project Documentation</h3>
              <p className="mb-6">We provide comprehensive documentation for all our client projects. Please log in to access your specific project details.</p>
              <motion.a
                href="#"
                className="bg-blue-500 text-white font-bold py-2 px-6 rounded-md relative overflow-hidden group inline-block"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                <span className="relative flex items-center">
                  <FileText className="mr-2" size={18} />
                  Access Documentation
                </span>
              </motion.a>
            </div>
          </motion.div>
        </div>
        <div className="absolute inset-0 bg-blue-500 opacity-5 transform -rotate-45 scale-150"></div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20 bg-gray-800 relative overflow-hidden">
        <div className="container mx-auto px-6 relative z-10">
          <motion.h2
            className="text-4xl font-bold mb-12 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            Get in Touch
          </motion.h2>
          <motion.div
            className="max-w-4xl mx-auto bg-gray-900 rounded-lg shadow-2xl overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2 p-8 bg-gradient-to-br from-blue-500 to-purple-600">
                <h3 className="text-2xl font-bold mb-4">Contact Information</h3>
                <p className="mb-4">Reach out to us for inquiries or to discuss your next project.</p>
                <p className="mb-2"><strong>Email:</strong> <a href='mailto:info@integritychain.co'> info@integritychain.co </a></p>
                <p className="mb-2"><strong>Phone:</strong> +90 (543) 802-3406</p>
                <p><strong>Address:</strong> Elmar Towers 8E/263 Konutkent - Ankara 06810 Turkiye</p>
              </div>
              <div className="md:w-1/2 p-8">
                <form>
                  <div className="mb-4">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium">Name</label>
                    <input type="text" id="name" className="w-full px-3 py-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium">Email</label>
                    <input type="email" id="email" className="w-full px-3 py-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className="block mb-2 text-sm font-medium">Message</label>
                    <textarea id="message" rows="4" className="w-full px-3 py-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"></textarea>
                  </div>
                  <motion.button
                    type="submit"
                    className="bg-blue-500 text-white font-bold py-2 px-6 rounded-md relative overflow-hidden group"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <span className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                    <span className="relative">Send Message</span>
                  </motion.button>
                </form>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="absolute inset-0 bg-blue-500 opacity-5 transform rotate-45 scale-150"></div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 py-6">
        <div className="container mx-auto px-6 flex justify-between items-center">
          <img src="logo.png" alt="integritychain Logo" className="h-8 w-12" />
          <p>&copy; 2024 integritychain. All rights reserved.</p>
        </div>
      </footer>

      {/* Floating Elements */}
      <div className="fixed inset-0 pointer-events-none z-20">
        <motion.div 
          className="absolute top-1/4 left-1/4 w-40 h-40 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-50"
          animate={{
            x: [0, 100, 0],
            y: [0, -100, 0],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
        <motion.div 
          className="absolute top-3/4 right-1/4 w-56 h-56 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-50"
          animate={{
            x: [0, -150, 0],
            y: [0, 100, 0],
            scale: [1, 1.5, 1],
          }}
          transition={{
            duration: 25,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      </div>

      {/* Expertise Modal */}
      <AnimatePresence>
        {activeExpertise && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={() => setActiveExpertise(null)}
          >
            <motion.div
              initial={{ scale: 0.9, y: 50 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 50 }}
              className="bg-gray-800 p-8 rounded-lg max-w-2xl w-full m-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center mb-4">
                {activeExpertise.icon}
                <h3 className="text-3xl font-bold ml-4">{activeExpertise.name}</h3>
              </div>
              <p className="mb-4">{activeExpertise.description}</p>
              <motion.button 
                onClick={() => setActiveExpertise(null)}
                className="bg-blue-500 text-white font-bold py-2 px-6 rounded-md relative overflow-hidden group"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                <span className="relative">Close</span>
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default App;